@import 'primereact/resources/themes/lara-dark-purple/theme.css';

// Tailwind CSS
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Lato', sans-serif;
}

body {
  background: #1F2937;
}

.no-build-zone-pattern {
  background-color: theme('colors.slate.900');
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
  opacity: 80%;
}